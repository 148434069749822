function updateScroll(e) {
  const isNavSticky = window.pageYOffset !== 0;
  const navEl = document.querySelector('.bl-site-header');

  if(isNavSticky) {
    navEl.classList.add('bl-site-header--sticky');
  } else {
    navEl.classList.remove('bl-site-header--sticky')
  }
}

function toggleNavigation(e) {
  const navEl = document.querySelector('.bl-site-header__nav');
  const btnEl = document.querySelector('.bl-site-header__show-more');
  const containerEl = document.querySelector('.bl-container');
  navEl.classList.toggle('bl-site-header__nav--open');
  btnEl.classList.toggle('bl-site-header__show-more--open');
  containerEl.classList.toggle('bl-container--overlay');
}

function toggleLangSwitch(e) {
  const langSwitchListEl = document.querySelector('.bl-lang-switch .bl-lang-switch__list');
  console.log(langSwitchListEl);
  langSwitchListEl.classList.toggle('bl-lang-switch__list--hidden');
}

document.onreadystatechange = () => {
  if (document.readyState === 'complete') {
    window.addEventListener('scroll', updateScroll);
    document.querySelector('.bl-site-header__show-more').addEventListener('click', toggleNavigation);
    document.querySelector('.bl-lang-switch .bl-lang-switch__toggle').addEventListener('click', toggleLangSwitch);

    updateScroll();
  }
};
